import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import styled from 'styled-components'

const about = ({ data }) => {
  return (
    <Layout>
      <SEO title="about" />
      <Wrapper>
        <div>
          <Img fluid={data.prismicLanding.data.mainpicture.fluid} /></div>
        <h1>{data.prismicLanding.data.title.text}</h1>
        <p>{data.prismicLanding.data.description.text}</p>
      </Wrapper>
    </Layout>
  )
}

export default about


export const query = graphql`
  {
  prismicLanding {
    data {
      description {
        text
      }
      mainpicture {
        fluid {
            ...GatsbyPrismicImageFluid
        }
      }
      title {
        text
      }
    }
  }
}
`
const Wrapper = styled.div`
padding-top: 100px;
display: flex;
align-items: center;
flex-direction: column;
background-color: #F4F3F3;
height: 100vh;
div{
  width:100%;
  max-width: 400px;
}
h1{ margin:20px auto;
font-size:1.5rem;}
p{
  margin:10px 50px;
}
`